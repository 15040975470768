@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: "JosefinSans-Thin";
  src: url(../assets/fonts/JosefinSans-Thin.ttf);
}
@font-face {
  font-family: "JosefinSans-ThinItalic";
  src: url(../assets/fonts/JosefinSans-ThinItalic.ttf);
}
@font-face {
  font-family: "JosefinSans-ExtraLight";
  src: url(../assets/fonts/JosefinSans-ExtraLight.ttf);
}
@font-face {
  font-family: "JosefinSans-ExtraLightItalic";
  src: url(../assets/fonts/JosefinSans-ExtraLightItalic.ttf);
}
@font-face {
  font-family: "JosefinSans-Light";
  src: url(../assets/fonts/JosefinSans-Light.ttf);
}
@font-face {
  font-family: "JosefinSans-LightItalic";
  src: url(../assets/fonts/JosefinSans-LightItalic.ttf);
}
@font-face {
  font-family: "JosefinSans-Regular";
  src: url(../assets/fonts/JosefinSans-Regular.ttf);
}
@font-face {
  font-family: "JosefinSans-Medium";
  src: url(../assets/fonts/JosefinSans-Medium.ttf);
}
@font-face {
  font-family: "JosefinSans-MediumItalic";
  src: url(../assets/fonts/JosefinSans-MediumItalic.ttf);
}
@font-face {
  font-family: "JosefinSans-SemiBold";
  src: url(../assets/fonts/JosefinSans-SemiBold.ttf);
}
@font-face {
  font-family: "JosefinSans-SemiBoldItalic";
  src: url(../assets/fonts/JosefinSans-SemiBoldItalic.ttf);
}
@font-face {
  font-family: "JosefinSans-Bold";
  src: url(../assets/fonts/JosefinSans-Bold.ttf);
}
@font-face {
  font-family: "JosefinSans-BoldItalic";
  src: url(../assets/fonts/JosefinSans-BoldItalic.ttf);
}

@font-face {
  font-family: "Montserrat-Light";
  src: url(../assets/fonts/Montserrat-Light.ttf);
}@font-face {
  font-family: "Montserrat-Medium";
  src: url(../assets/fonts/Montserrat-Medium.ttf);
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "JosefinSans-Light", -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
  color: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.family-thin {
  font-family: "JosefinSans-Thin" !important;
}
.family-thin-italic {
  font-family: "JosefinSans-ThinItalic" !important;
}
.family-extra-light {
  font-family: "JosefinSans-ExtraLight" !important;
}
.family-extra-light-italic {
  font-family: "JosefinSans-ExtraLightItalic" !important;
}
.family-light {
  font-family: "JosefinSans-Light" !important;
}
.family-light-italic {
  font-family: "JosefinSans-LightItalic" !important;
}
.family-regular {
  font-family: "JosefinSans-Regular" !important;
}
.family-medium {
  font-family: "JosefinSans-medium" !important;
}
.family-medium-italic {
  font-family: "JosefinSans-MediumItalic" !important;
}
.family-semibold {
  font-family: "JosefinSans-SemiBold" !important;
}
.family-semibold-italic {
  font-family: "JosefinSans-SemiBoldItalic" !important;
}
.family-bold {
  font-family: "JosefinSans-Bold" !important;
}
.family-bold-italic {
  font-family: "JosefinSans-BoldItalic" !important;
}

.family-montlight {
  font-family: "Montserrat-Light" !important;
}
.family-montmedium {
  font-family: "Montserrat-Medium" !important;
}


.font-white {
  color: #ffffff !important;
}
.font-black {
  color: #000000 !important;
}
.font-red {
  color: #7b183f !important;
}
.font-blue {
  color: #45506e !important;
}
.font-cream {
  color: #d7e7bd !important;
}
.font-gray2 {
  color: #4d4d4e !important;
}
.font-gray {
  color: #aeaeb3 !important;
}


.font-xs {
  font-size: 0.75rem;
}
.font-small {
  font-size: 0.875rem;
}
.font-normal {
  font-size: 1rem;
}
.font-medium {
  font-size: 1.2rem;
}
.font-regular {
  font-size: 1.6rem;
}
.font-lg {
  font-size: 2rem;
}
.font-big {
  font-size: 3rem;
}


.back-top {
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.back-top svg {
  color: #1a1a1a !important;
}

.box-number {
  background: rgba(255, 255, 255, 0);
  animation-name: box-show;
  animation-duration: 2s;
}


.topo {
  background: url(../assets/background/topo.png) center no-repeat;
  background-size: cover;
}
.familia {
  background-color: #f5f5f2;
  background: url(../assets/background/bg-familia.jpeg) bottom center no-repeat;
}
.entreter {
  background: url(../assets/background/bg-entreter.jpeg) top center no-repeat;
  background-size: cover;
}
.plantas {
  background: url(../assets/background/bg-planta.jpeg) center no-repeat;
  padding-top: 4rem;
}
.mapa {
  background: url(../assets/background/mapa.png) center no-repeat;
  background-size: cover;
}


.box-planta {
  background-color: #fff;
  border-radius: 3rem;
  line-height: 150%;
}
.planta-info {
  font-size: 2em;
}
.planta-attention strong{
  font-family: "JosefinSans-Bold" !important;
}
.planta-attention {
  font-size: 1.4em;
}

.mapa-title {
  font-size: 2.2em;
}
.mapa-desc {
  font-size: 1.8em;
}
.mapa-phone {
  font-size: 2.4em;
}
.mapa-address {
  font-size: 2em;
  line-height: 120%;
}

.button-mapa {
  border-width: 1px;
  border-color: #7d8392;
  background-color: transparent;
  font-family: "JosefinSans-LightItalic";
  font-size: 1em;
  color: gray;
  width: 8rem;
  height: 2rem;
  border-radius: 1rem;
  margin-top: 500px;
  margin-right: 160px;
}

.title-topo {
  color: #7b183f;
  font-size: 3rem;
  margin-top: -60px;
}
.topo-img-vida {
  position: absolute;
  margin-top: -76px;
  margin-left: 180px;
  width: 20rem;
}

.entreter-box {
  display: flex;
  align-items: center;
}
.entreter-title {
  display: flex;
  align-items: center;
  align-content: center;
  font-size: 2.25em;
}
.entreter-content {
  display: flex;
  flex-direction: column;
}
.entreter-text {
  font-size: 1.7em;
}

.descricao-text {
  font-size: 1.5em;
  color: #45506e;
  font-family: "JosefinSans-medium";
}

.button-53 {
  border-top-right-radius: 1.5rem; 
  border-bottom-right-radius: 1.5rem; 
  border-top-left-radius: 1.5rem; 
  border-width: 1px;
  border-color: #c99f8d;
  background-color: #c99f8d;
  color: white;
  font-family: "JosefinSans-Light";
  font-size: 1.8em;
}
.button-53:focus {
  background-color: #7b183f;
}
.button-68:focus {
  background-color: #7b183f;
}
.button-68 {
  border-top-left-radius: 1.5rem; 
  border-bottom-left-radius: 1.5rem; 
  border-top-right-radius: 1.5rem; 
  border-width: 1px;
  border-color: #c99f8d;
  background-color: #c99f8d;
  color: white;
  font-family: "JosefinSans-Light";
  font-size: 1.8em;
}
.bg-plantas {
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(../assets/file/Plantas.png) top no-repeat;
  height: 160px;
}
.bg-familia {
  background: url(../assets/file/lar.png) center no-repeat;
}
.bg-descricao {
  background: url(../assets/background/descricao.jpeg) right center no-repeat;
  height: 60rem;
}
.bg-piscina {
  background: url(../assets/file/piscina.png) left no-repeat;
  height: 12rem;
}
.bg-playground {
  background: url(../assets/file/Playground.png) center no-repeat;
  height: 10rem;
}
.bg-quadra {
  background: url(../assets/file/quadra.png) left no-repeat;
  height: 16rem;
}
.bg-quiosque {
  background: url(../assets/file/Quiosques.png)  left no-repeat;
}
.bg-salao {
  background: url(../assets/file/salao.png) center no-repeat;
}


.logotipo {
  margin-top: -100px;
  animation: bounce 30s infinite;
}
.imgMac {
  width: 100%;
  height: auto;
}
.title {
  font-family: SF-Pro-Display-Light;
  border-left: 6px solid #7b183f;
  padding-left: 24px;
}
.title-blue {
  font-family: SF-Pro-Display-Light;
  border-left: 6px solid #45506e;
  padding-left: 24px;
}
.title-red strong {
  font-family: SF-Pro-Display-Bold;
  color: #7b183f;
  font-size: 1.8rem;
  margin-right: 1rem;
}
.line-height {
  line-height: 2rem;
}
.bg-red {
  background-color: #7b183f;
}
.button-cadastro {
  background-color: #7b183f;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 1em;
}
.button-retornar {
  background-color: #7b183f;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 1em;
}


.bars-tecnology {
  animation-name: slidein;
}
.btn-gray {
  background: #7d8392;
  width: 350px;
}
.btn-black:hover {
  background: #1a1a1a;
}
.btn-red {
  background: #7b183f;
  width: 350px;
}
.btn-red:hover {
  background: #7b183f;
}
.tec_dir {
  border-top-left-radius: 16px;
}
.tec_left {
  border-bottom-right-radius: 16px;
}
strong {
  font-family: JosefinSans-Light;
}
.formulario {
  background: none;
  border: 1px solid #45506e;
  border-radius: 8px;
}
.whats-message {
  z-index: 999;
}

.w3-modal-content {
  border-radius: 2em;
}

.w3-container {
  padding: 2em 2em 0.8em;
}

.modal-cancel {
    background-image: none;
    border: none;
    box-shadow: none;
    text-shadow: none;
    background-color: #EEEEEE;
    border-color: #ECECEC;
    border-radius: 0px;
    border-width: 1px;
    border-style: solid;
    color: #CCCCCC;
    height: 42px;
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0em;
    line-height: 1;
    text-transform: capitalize;
    margin-bottom: 10px;
    margin-top: 0;
    padding-left: 5px !important;
    padding-right: 5px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.splide__pagination__page.is-active {
  background-color: #7b183f !important;
}

.splide__arrow svg {
  fill: #7b183f !important;
}


@media (max-width: 1400px) {
  .title-topo {
    color: #7b183f;
    font-size: 3rem;
    margin-top: -110px;
    margin-left: 30px;
  }
  .topo-img-vida {
    position: absolute;
    margin-top: -78px;
    width: 20rem;
  }
  .bg-descricao {
    background: url(../assets/background/descricao.jpeg) right center no-repeat;
    height: 60rem;
    margin-right: -200px;
  }
}
@media (max-width: 991px) {
}
@media (max-width: 767px) {
  .font-big {
    font-size: 2.4rem;
  }
  .btn-black {
    width: 100%;
  }
  .btn-red {
    width: 100%;
  }

  .topo {
    background: url(../assets/background/topo.png) center no-repeat;
    background-size: cover;
  }
  
  .familia {
    background-color: #f5f5f2;
    background: url(../assets/background/bg-familia.jpeg) bottom center no-repeat;
  }
  .button-cadastro {
    background-color: #7b183f;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.8em;
  }
  
  .mapa-title {
    font-size: 1.5em;
  }
  .mapa-desc {
    font-size: 1.5em;
  }
  .mapa-phone {
    font-size: 2.2em;
  }
  .mapa-address {
    font-size: 1.75em;
    line-height: 120%;
  }

  .plantas {
    background: url(../assets/background/bg-planta.jpeg)  center no-repeat;
    padding-top: 2rem;
  }
  .box-planta {
    background-color: #fff;
    border-radius: 1rem;
    line-height: 150%;
  }
  .planta-info {
    font-size: 1.5em;
  }
  .planta-attention {
    font-size: 1em;
  }

  .entreter-box {
    display: block;
    align-items: center;
  }
  .entreter-title {
    display: flex;
    align-items: center;
    align-content: center;
    font-size: 1.25em;
  }
  .entreter-content {
    display: flex;
    flex-direction: column;
  }
  .entreter-text {
    font-size: 1.25em;
  }
  .descricao-text {
    font-size: 1.25em;
  }
}


@media (max-width: 767px) {
  .title-topo {
    color: #7b183f;
    font-size: 2.5rem;
    margin-top: -60px;    
    margin-left: 130px;

  }
  .topo-img-vida {
    position: absolute;
    margin-top: -55px;
    margin-left: 150px;
    width: 16rem;
  }
  .topo {
    background: url(../assets/background/topo.png) no-repeat;
    background-size: auto;
    height: 712px;
  }
.familia {
  background-color: #f5f5f2;
  background: url(../assets/background/bg-familia.jpeg) bottom right no-repeat;
  }
.bg-piscina {
  background-image: none;
}
.bg-playground {
  background-image: none;
}
.bg-quadra {
  background-image: none;
}
.bg-quiosque {
  background-image: none;
}
.bg-salao {
  background-image: none;
}
.mapa {
  background-image: none;
}
.bg-descricao {
  background-image: none;
  margin-bottom: -400px;
}
.descricao-text {
  font-size: 1.8em;
  color: #45506e;
  font-family: "JosefinSans-medium";
}
.button-53 {
  border-top-right-radius: 1.5rem; 
  border-bottom-right-radius: 1.5rem; 
  border-top-left-radius: 1.5rem; 
  border-width: 1px;
  border-color: #7b183f;
  background-color: #7b183f;
  color: white;
  font-family: "JosefinSans-Light";
  font-size: 1.5em;
}
.button-53:active {
  background-color: #c99f8d;
}
.button-68:active {
  background-color: #7b183f;
}
.button-68 {
  border-top-left-radius: 1.5rem; 
  border-bottom-left-radius: 1.5rem; 
  border-top-right-radius: 1.5rem; 
  border-width: 1px;
  border-color: #c99f8d;
  background-color: #c99f8d;
  color: white;
  font-family: "JosefinSans-Light";
  font-size: 1.5em;
}
.plantas {
  background: url(../assets/background/bg-planta.jpeg)  center no-repeat;
  padding-top: 2rem;
}
}


@media (max-width: 575px) {
  .button-53 {
    font-family: "JosefinSans-Light";
    background: #7b183f;
    margin-top: -38px;
    margin-right: 0.5rem;
    padding-left: 3rem ;
    padding-right: 3rem ;
    font-size: 1em;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    color: white;
  }
  .button-68 :active {
    background: #7b183f;
  }
  .button-53 :active {
    background: #c99f8d;
  }
  .button-68 {
    font-family: "JosefinSans-Light";
    background: #c99f8d;
    margin-top: -38px;
    margin-left: 0.5rem;
    font-size: 1em;
    padding-left: 3rem ;
    padding-right: 3rem ;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem; 
    color: white;
  }
  .bg-plantas {
    display: flex;
    justify-content: center;
    align-items: center;
    background: url(../assets/file/Plantas.png) top no-repeat;
    height: 160px;
  }
  .plantas {
    background: url(../assets/background/bg-planta.jpeg)  center no-repeat;
    padding-top: 2rem;
  }
  .font-big {
    font-size: 2.4rem;
  }
  .btn-black {
    width: 100%;
  }
  .btn-red {
    width: 100%;
  }
  .entreter-title {
    display: flex;
    align-items: center;
    align-content: center;
    font-size: 1.5em;
  }
  .entreter-content {
    display: flex;
    flex-direction: column;
  }
  .entreter-text {
    font-size: 1.25em;
  }
  .title-topo {
    color: #7b183f;
    font-size: 2rem;
    margin-top: -40px;
    margin-left: 70px;
  }
  .topo-img-vida {
    position: absolute;
    margin-top: -38px;
    width: 10rem;
  }
  .topo {
    background: url(../assets/background/topo.png) no-repeat;
    background-size: cover;
    height: 100vh;
  }
  .bg-descricao {
  background-image: none;
}
  .bg-piscina {
    background-image: none;
  }
  .bg-playground {
    background-image: none;
  }
  .bg-quadra {
    background-image: none;
  }
  .bg-quiosque {
    background-image: none;
  }
  .bg-salao {
    background-image: none;
  }
  .mapa {
    background-image: none;
  }
  .mapa-title {
    font-size: 1.5em;
  }
  .mapa-desc {
    font-size: 1.5em;
  }
  .mapa-phone {
    font-size: 2em;
  }
  .mapa-address {
    font-size: 1.5em;
    line-height: 120%;
  }
  .descricao-text {
    font-size: 1.5em;
    color: #45506e;
    font-family: "JosefinSans-medium";
  }
}
@media (max-width: 395px) {

.title-topo {
  color: #7b183f;
  font-size: 1.8rem;
  margin-top: -10px;
  margin-left: 50px;
}
.topo-img-vida {
  position: absolute;
  margin-top: -38px;
  margin-left: 120px;
  width: 10rem;
}
.bg-descricao {
  background-image: none;
  margin-bottom: -540px;
}
.descricao-text {
  font-size: 1em;
  color: #45506e;
  font-family: "JosefinSans-medium";
}
}

/* SCROLLBAR */
@media only screen and (min-width: 600px) {
  * {
    scrollbar-width: thin;
    scrollbar-color: #7b183f;
  }

  *::-webkit-scrollbar {
    width: 12px;
  }

  *::-webkit-scrollbar-track {
    background: #d7c7bd;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #7b183f;
    border-radius: 20px;
    border: 3px solid #d7c7bd;
  }

  * {
    box-sizing: border-box;
  }
}
/* SCROLLBAR */
